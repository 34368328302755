<template>
  <div id="user.activate.resend">
    <b-row class="m-0 p-4">
      <b-col md="3"></b-col>
      <b-col md="6">
        <b-card>
          <div v-if="resend === true">
            The activation code was succesfully send to your email. Check your
            <br />
            mail box to see the code.
          </div>
          <div v-else>
            <email />
            <b-row>
              <b-col class="d-flex mt-2 justify-content-center"
                ><resend-button :active="valid" />
              </b-col>
            </b-row>
            <div v-if="resend === false" class="mt-2 text-center">
              Failed to send an activation code <br />
              See if you already activated your account by
              <b-link :to="{ name: 'login' }">Login</b-link><br />
              Otherwise take contact with the system admin
              <b-link :to="{ name: 'feedback' }">Contact</b-link>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import email from "../form/activate/email";
import resendButton from "../components/resendButton";
import logic from "@/scripts";
export default {
  name: "user.activate.resend",
  components: {
    email,
    resendButton,
  },
  computed: {
    ...mapGetters({
      form: "getActivateResendForm",
      resend: "getActivationResend",
    }),
    valid() {
      return logic.input.user.activate.validate(this.form.email);
    },
  },
};
</script>

<style>
</style>