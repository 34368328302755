<template>
  <div id="user.components.resendButton">
    <b-button variant="success" size="lg" :disabled="!active" @click="clicked()"
      ><i class="fas fa-envelope-open-text"></i>
      {{ $t("user.form.activate.buttons.resend") }}</b-button
    >
  </div>
</template>

<script>
export default {
  name: "user.components.resendButton",
  props: ['active'],
  methods: {
    clicked() {
      this.$store.dispatch("activationCodeResend")
    },
  },
};
</script>

<style>
</style>