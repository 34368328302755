<template>
  <div id="user.activate.activate">
    <b-row class="m-0 p-4">
      <b-col md="3"></b-col>
      <b-col md="6">
        <b-card>
          <div class="text-center">
            <div v-if="activated">
              Account Succesfully activated. <br />
              You can now <b-link :to="{ name: 'login' }">Login</b-link>
            </div>
            <div v-else>
              Failed to activated your account.<br />
              See if you already activated your account by
              <b-link :to="{ name: 'login' }">Login</b-link><br />
              Otherwise take contact with the system admin
              <b-link :to="{ name: 'feedback' }">Contact</b-link>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      activated: "getActivatedStatus",
    }),
  },
};
</script>

<style>
</style>